const { lang, LAYOUT, SiteUID, IS_RW, templateType } = gbCommonInfo

import { getLocalStorage, parseQueryString, setLocalStorage } from '@shein/common-function'

import './analysis'
import { popupQuestionnaire, clickPopupQuestionnaire } from './analysisModule'
import urlsConfig from './urls-config'
import Content from './Content.vue'
import { throttle } from 'lodash'
import { createApp } from 'vue'
import { registerBaseContext } from '../../common/common_inject'
import schttp from 'public/src/services/schttp'
import { isLogin } from 'public/src/pages/common/utils/index.js'
import {
  changeNodeClassList,
  getDOMNode,
  hasClass,
} from '@/common/dom-utils'

/**
 * 依赖 ccc_bottom_policy 中的 firstCouponSite 数据 和 appFirstOrderCoupon 数据
 * 
 */
function upToTopInit (){
  let showMore, showApp, showQuestionnaire
  if(LAYOUT === 'layout') {
    showMore = true
    showApp = true
    showQuestionnaire = true
  } else {
    showQuestionnaire = typeof templateType != 'undefined' && templateType == 'cart'
  }

  const upToTopVM = createApp({
    name: 'UpToTop',
    components: {
      // eslint-disable-next-line vue/no-reserved-component-names
      Content
    },
    data() {
      return {
        firstCouponSite: {},
        appFirstOrderCoupon: {},
        showMore,
        showApp,
        showQuestionnaire,
      }
    },
    template: `<Content :showMore="showMore" :showApp="showApp" :showQuestionnaire="showQuestionnaire" :firstCouponSite="firstCouponSite" :appFirstOrderCoupon="appFirstOrderCoupon" />`,
  })
  registerBaseContext(upToTopVM)
  const appUpToTopVm = upToTopVM.mount('.j-up-to-top_container')

  getDOMNode('.j-uptt').addEventListener('click', () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  })

  const backWrapEl = getDOMNode('.j-back-wrap')
  window.addEventListener('scroll', throttle(() => {
    const st = window.scrollY || window.pageYOffset
    // 问卷弹窗存在5s内不隐藏
    if (st <= 0 && hasClass('.show-questionnaire__info', 'she-hide')) {
      backWrapEl.classList.remove('she-show')
    } else if (st > 0 && !backWrapEl.classList.contains('she-show')) {
      backWrapEl.classList.add('she-show')
    }
  }, 200, { 'trailing': true }))

  // 二维码img 及本地化链接
  var qrCodeImgEl = document.querySelectorAll('.j-back-wrap .j-download-warp .qr-code img')
  var newAppInfoEl = getDOMNode('.j-back-wrap .j-download-warp .j-new-app-info')
  var newAppInfoAndroidEl = getDOMNode('.j-back-wrap .j-download-warp .j-new-app-info-android')
  if (qrCodeImgEl) {
    [].slice.call(qrCodeImgEl).forEach(el => {
      el.setAttribute('src', IS_RW ? (urlsConfig.rwImgUrls[SiteUID] || urlsConfig.rwImgUrls['default']) : (urlsConfig.imgUrls[SiteUID] || urlsConfig.imgUrls['default']))
    })
    // qrCodeImgEl.src = IS_RW ? (urlsConfig.rwImgUrls[SiteUID] || urlsConfig.rwImgUrls['default']) : (urlsConfig.imgUrls[SiteUID] || urlsConfig.imgUrls['default'])
  }
  if (newAppInfoEl) {
    newAppInfoEl.setAttribute('href', IS_RW ? (urlsConfig.rwUrls[SiteUID] || urlsConfig.rwUrls['default']) : (urlsConfig.sheinUrls[SiteUID] || urlsConfig.sheinUrls['default']) )
  }
  if (newAppInfoAndroidEl) {
    newAppInfoAndroidEl.setAttribute('href', IS_RW ? (urlsConfig.rwUrlsAndroid[SiteUID] || urlsConfig.rwUrlsAndroid['default']) : (urlsConfig.sheinUrlsAndroid[SiteUID] || urlsConfig.sheinUrlsAndroid['default']) )
  }

  // youtube 视频
  if (LAYOUT == 'layout' && (lang == 'us' || lang == 'uk')) {
    var tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    var firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

    var ftplayer
    window.onYouTubeIframeAPIReady = function() {
      let videoId = 'EjzKbOV8mNs'
      // eslint-disable-next-line no-undef
      ftplayer = new YT.Player('ftplayer', {
        width: '560',
        height: '315',
        videoId,
        playerVars: {
          rel: 0,
          controls: 2,
          showinfo: 0
        }
      })
    }

    getDOMNode('.j-video-icon')?.addEventListener('click', () => {
      const jVidewMask = getDOMNode('.j-video-mask')
      const jVideoWrap = getDOMNode('.j-video-wrap')

      jVidewMask.style.display = 'block'
      changeNodeClassList(
        jVideoWrap,
        { remove: ['home-video__enterIn'] },
        { add: ['home-video__enterIn', 'home-video__flashAnimation'] }
      )
      jVideoWrap.addEventListener('animationend', () => {
        changeNodeClassList(
          jVideoWrap,
          { remove: ['home-video__enterIn'] }
        )
        ftplayer.playVideo()
      }, { once: true })
    })

    getDOMNode('.j-hide-video')?.addEventListener('click', () => {
      const jVideoWrap = getDOMNode('.j-video-wrap')
      changeNodeClassList(
        jVideoWrap,
        { remove: ['home-video__jumpOut'] },
        { add: ['home-video__jumpOut', 'home-video__flashAnimation'] }
      )
      jVideoWrap.addEventListener('animationend', () => {
        changeNodeClassList(
          jVideoWrap,
          { remove: ['home-video__jumpOut'] }
        )
        getDOMNode('.j-video-mask').style.display = 'none'
        ftplayer.pauseVideo()
      }, { once: true })
    })
  }

  // 问卷弹窗
  class InitQuestionnaireDialog {
    constructor() {
      this.init()
    }
    async init() {
      let questionnaireEl = getDOMNode('.j-show-questionnaire')

      // 门店搜索结果页不显示
      const query = parseQueryString(location.search)
      const isStoreSearch = query.search_type === 'store' && query.type === 'search'

      if (!questionnaireEl || !isLogin() || isStoreSearch) { return }

      const count = await this.getSurveyCount()
      if (count) {
        questionnaireEl.style.display = 'block'
        questionnaireEl.addEventListener('click', () => {
          if (!hasClass('.show-questionnaire__info', 'she-hide')) {
            this.hideDialog()
          }
          clickPopupQuestionnaire()
          window.location = '/user/survey'
        })

        // 24小时内不再弹出
        if ((window.location.pathname !== '/') || getLocalStorage('isHideQuestionnaireDialog')) {
          return
        }

        const questionnaire = await this.getQuestionnaireDialog()
        if (questionnaire.length) {
          const { mainTitle = '', otherTitle = '' } = questionnaire[0].content[0].content.props.items[0]
          getDOMNode('.show-questionnaire .txt').textContent = mainTitle
          getDOMNode('.show-questionnaire .desc').textContent = otherTitle
          var backWrapEl = getDOMNode('.j-back-wrap')
          if (!backWrapEl.classList.contains('she-show')) {
            backWrapEl.classList.add('she-show')
          }
          changeNodeClassList(
            '.show-questionnaire__info',
            { remove: ['she-hide'] }
          )
          popupQuestionnaire()
          setTimeout(() => {
            this.hideDialog()
          }, 5 * 1000)
        }
      }
    }
    async getSurveyCount() {
      const res = await schttp({
        url: `/user/survey/getCount`,
      })
      const { code, info } = res
      return Promise.resolve(code === '0' ? info : 0)
    }
    async getQuestionnaireDialog() {
      const res = await schttp({
        url: `/questionnaireDialog`,
      })
      const { code, data } = res
      return Promise.resolve(code === '1000' ? data : [])
    }
    hideDialog () {
      const isHideQuestionnaireDialog = getLocalStorage('isHideQuestionnaireDialog')
      if (!isHideQuestionnaireDialog) {
        setLocalStorage({ key: 'isHideQuestionnaireDialog', value: '1', expires: 24 * 60 * 60 * 1000 })
      }
      changeNodeClassList(
        '.show-questionnaire__info',
        { add: ['she-hide'] }
      )
    }
  }
  new InitQuestionnaireDialog()
  return appUpToTopVm
}

export {
  upToTopInit
}
